@import "./Variables.scss";




body {
  font-family: $fontfamily;
}

.home_banner {
  background: url(../../src/Assests/images/home_banner.png);
  background-size: cover;
  padding: 53px 58px 95px 80px;

  @media(max-width: 767px) {
    padding: 20px;
  }

  .row {
    width: 100%;
  }

  .navbar-nav {
    flex-flow: row;
    justify-content: end;
    margin-top: 0px;

    .nav-item {
      margin: 0 7px;

      a {
        color: $secoundaryColor;
        padding: 0 5px !important;
        border: 0;

        img {
          max-width: 33px;
        }
      }

      .nav-link.dropdown-toggle {
        border: 2px solid $secoundaryColor !important;
        border-radius: 5px;
      }
    }
  }

  .banner_heading {
    margin-top: 181px;
    font-size: 7.3rem;
    text-align: center;
    color: $secoundaryColor;
    font-weight: 600;
    margin-bottom: 68px;
    font-family: $fontfamilyPro;
    letter-spacing: 1px;

    @media(max-width: 1400px) {
      font-size: 6rem;
      margin-top: 130px;
      margin-bottom: 50px;
    }

    @media(max-width: 991px) {
      margin-top: 40px;
      font-size: 2rem;
      margin-bottom: 20px;
    }
  }


  .banner_form {
    max-width: 1054px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;

    @media(max-width: 767px) {
      display: block;
    }
  }

  .select_link {
    background: url(../../src/Assests/images/arrow.png);
    background-size: 10px;
    background-position: 98% 40px;
    background-repeat: no-repeat;
    width: 45%;
    color: $secoundaryColor;
    background-color: transparent;
    border: 0;
    border-top: 4px solid $secoundaryColor;
    outline: none !important;
    font-size: 28px;
    text-align: left;
    text-decoration: none;
    padding-top: 27px;
    font-weight: 600;
    letter-spacing: 4px;
    transition: all ease 1s;

    @media(max-width: 767px) {
      width: 100%;
      margin: 20px 0;
    }

    &:hover {
      color: #caa616 !important;
      background: url(../../src/Assests/images/arrow_hover.png) no-repeat;
      background-size: 10px;
      background-position: 100% 40px;
      width: 45%;
    }
  }

  .scroll_btn {
    /* background: url(../../src/Assests/images/scroll_btn.png);
    background-position: center 100%;
    background-repeat: no-repeat; */
    font-size: 20px;
    border: 0;
    color: $secoundaryColor;
    height: 80px;
    position: relative;
    margin-left: calc(100% - 122px);
    width: 70px;
    text-align: center;
    margin-top: 75px;
    letter-spacing: 1.5px;
    background-color: transparent;

    @media(max-width: 767px) {
      margin-top: 30px;
    }
  }

  @keyframes MoveUpDown {

    0%,
    100% {
      bottom: 0;
    }

    50% {
      bottom: -10px;
    }
  }

  .scroll_btn::after {
    background: url(../../src/Assests/images/scroll_btn.png);
    background-position: center 100%;
    background-repeat: no-repeat;
    position: absolute;
    top: 0;
    margin: auto;
    left: 0;
    right: 0;
    animation: MoveUpDown 1s linear infinite;
    content: '';
  }

  .scroll_btn::before {
    content: 'Scroll';
    position: absolute;
    top: 0;
    margin: auto;
    left: 0;
    right: 0;
  }
}

.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.sec01 {
  margin: 80px 0;
  color: $primaryColor;
  font-family: $fontfamilyPro;

  @media(max-width: 767px) {
    margin: 40px 0 0;
  }

  .sec01_content {
    display: flex;
  }

  .heading {
    position: relative;
    padding-left: 55px;
    font-size: 20px;
    color: $primaryColor;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 3px;
    font-family: $fontfamilyPro;

    @media(max-width: 768px) {
      font-size: 20px;
      padding: 0 55px;
    }

    &::before {
      content: '';
      height: 2px;
      width: 50px;
      background-color: $hoverColor;
      position: absolute;
      left: 0;
      top: 10px;

    }

  }

  p {
    color: $grayColor;
    font-size: 22px;
  }

  .sec_bold {
    font-size: 38px !important;
    color: $primaryColor !important;
    margin: 10px 0;
  }

  .sec_01_left,
  .sec_01_right {
    width: 50%;
    justify-content: center;
    display: flex;
    flex-flow: column;

    @media(max-width: 767px) {
      width: 100%;
      margin-bottom: 20px;
    }
  }

  .lear_mor_link {
    background: url(../../src/Assests/images/arrow_yellow.png);
    background-position: center right;
    background-repeat: no-repeat;
    font-weight: 700;
    text-transform: uppercase;
    width: 160px;
    color: $primaryColor;
    font-family: $fontfamilyPro;
    padding-right: 20px;
    display: inline-block;
    font-size: 20px;
    text-decoration: none;
    letter-spacing: 3px;
    transition: all ease 2s;

    &:hover {
      color: $hoverColor;
      padding-right: 30px;
      width: 170px;
    }
  }
}

.sec02 {
  background-color: #ecf1f5;
  padding: 100px 0;

  @media(max-width: 768px) {
    padding: 30px 0;
  }

  .heading {
    position: relative;
    text-align: center;
    font-size: 55px;
    color: $primaryColor;
    font-weight: 600;
    margin-bottom: 100px;
    font-family: $fontfamilyPro;

    @media(max-width: 768px) {
      font-size: 30px;
      padding: 0 20px;
      margin-bottom: 40px;
    }

    &::before {
      content: '';
      height: 2px;
      width: 50px;
      background-color: #d1b12f;
      position: absolute;
      bottom: -20px;
      margin: auto;
      left: 0;
      right: 0;

    }
  }

  .swiper-horizontal {
    touch-action: pan-y;
    padding-bottom: 70px;

    @media(max-width: 768px) {
      padding-bottom: 20px;
    }

    .swiper-pagination.swiper-pagination-progressbar.swiper-pagination-horizontal {
      top: initial;
      bottom: 0px;
      background-color: #abb9ca;
      height: 2px;
    }

    .swiper-pagination-progressbar .swiper-pagination-progressbar-fill {
      background-color: #153867;
    }

    .swiper-button-next,
    .swiper-button-prev {
      top: 94%;

    }

    .swiper-button-next {
      right: calc(100% - 80px);
    }

    .swiper-button-next:after,
    .swiper-button-prev:after {
      font-size: 20px;
      color: gold;
    }
  }

  .lear_mor_link {
    background: url(../../src/Assests/images/arrow_yellow_2.png);
    background-position: center right;
    background-repeat: no-repeat;
    font-weight: 700;
    color: $primaryColor;
    font-family: $fontfamilyPro;
    padding-right: 30px;
    margin-top: 28px;
    display: inline-block;
    font-size: 20px;
    text-transform: uppercase;
    letter-spacing: 2px;
    text-decoration: none;
    transition: all ease 2s;

    &:hover {
      color: $hoverColor;
      padding-right: 40px;
    }
  }
}

.sec03 {
  background: url(../../src/Assests/images/banner_02.png);
  background-repeat: no-repeat;
  background-size: cover;
  color: #fff !important;
  padding: 300px 0;
  text-align: left;
  margin: 0;

  @media(max-width: 768px) {
    padding: 30px 0;
  }

  .sec_bold,
  .heading {
    color: #fff !important;
  }

  .sec_bold {
    font-size: 50px !important;
    max-width: 500px;

    @media(max-width: 768px) {
      font-size: 24px !important;
    }
  }
}

.sec04 {
  .sec_01_left {
    padding-left: calc(50% - 600px);

    @media(max-width: 1200px) {
      padding-left: 100px;
    }

    @media(max-width: 768px) {
      padding-left: 20px;
    }
  }

  @media(max-width: 768px) {
    .col-6 {
      width: 100% !important;
    }
  }

  .swiper-wrapper {
    /*  right: -25%; */
    margin-top: 70px;

    @media(max-width: 1200px) {
      right: 0;
    }

    .col-6 {
      @media(max-width: 768px) {
        width: 100%;
      }
    }

    img {
      width: 100%;
    }
  }

  .swiper-button-next,
  .swiper-button-prev {
    top: 30px;
    left: 46%;
    border: 1px solid #173a68;
    padding: 11px !important;
    width: 40px;
    border-radius: 8px;

    @media(max-width: 992px) {
      left: 0;
    }

    &:hover {
      background-color: $primaryColor;
      &:after{
        color: $secoundaryColor !important;
      }
    }
  }

  .swiper-button-next {
    left: calc(64% - 100px);

    @media(max-width: 992px) {
      left: 60px;
    }
  }

  .swiper-button-next:after,
  .swiper-button-prev:after {
    font-size: 20px;
    color: #173a68;

  }


  h3 {
    color: $primaryColor;
    font-size: 30px;
    text-align: left;
    margin: 20px 20px 0;
  }

  p {
    color: $grayColor;
    font-size: 19px;
    text-align: left;
    margin: 0 20px 20px;
  }

  .swiper-slide {
    border: 1px solid #eaeaea;
  }
}

.sec05 {
  .container-fluid {
    overflow: hidden;
  }

  .swiper-horizontal {
    width: calc(100% + 200px);
    margin-left: -100px;

    img {
      width: 100%;
    }
  }
}

.sec06 {
  background: url(../../src/Assests/images/testimonial_bg.png);
  background-repeat: no-repeat;
  background-size: cover;
  color: #fff;
  padding: 100px 0;

  @media(max-width: 768px) {
    padding: 30px 0;

  }

  @media(max-width: 1200px) {
    img {
      width: 100%;
    }
  }

  .swiper-button-next,
  .swiper-button-prev {

    border: 1px solid #fff;
    padding: 11px !important;
    width: 40px;
    border-radius: 8px;

    @media(max-width: 1200px) {
      background-color: $primaryColor;
    }
  }

  .swiper-button-next:after,
  .swiper-button-prev:after {
    font-size: 20px;
    color: #fff;
  }

  .heading {
    position: relative;
    text-align: center;
    font-size: 55px;
    color: $primaryColor;
    font-weight: 600;
    margin-bottom: 100px;
    font-family: $fontfamilyPro;

    @media(max-width: 768px) {
      font-size: 30px;
      padding: 0 20px;
      margin-bottom: 40px;
    }

    &::before {
      content: '';
      height: 2px;
      width: 50px;
      background-color: #d1b12f;
      position: absolute;
      bottom: -20px;
      margin: auto;
      left: 0;
      right: 0;

    }
  }
}

.sec07 {

  .card {
    width: calc(50% - 10px);
    float: left;
    justify-content: space-between;

    @media(max-width: 768px) {
      width: 100%;
    }

    .card-title {
      font-size: 25px;
      font-family: $fontfamilyPro;
      color: $grayColor;
      font-weight: bold;
    }

    a {
      font-size: 17px;
      font-family: $fontfamilyPro;
      color: $primaryColor;
      font-weight: bold;
      text-transform: uppercase;
      text-decoration: none;
    }

    .card-text {
      font-size: 20px;
      font-family: $fontfamilyPro;
      color: $primaryColor;


    }

    &:first-child {
      display: flex;
      flex-flow: row;
      width: 100%;
      margin: 20px 0;

      @media(max-width: 1200px) {
        flex-flow: column;
      }
    }

    &:last-child {
      margin-left: 20px;
    }
  }

  .heading {
    text-align: left;
    margin-bottom: 50px;

    @media(max-width: 768px) {
      margin-bottom: 20px;
    }
  }

  .lear_mor_link {
    background: url(../../src/Assests/images/arrow_yellow.png) !important;
    background-position: center right !important;
    background-repeat: no-repeat !important;
    font-weight: 700;
    color: #183a66;
    font-family: "Dax Pro Regular";
    padding-right: 30px;
    margin-top: 28px;
    display: inline-block;
    font-size: 20px;
    text-transform: uppercase;
    letter-spacing: 2px;
    text-decoration: none;
    width: inherit;
    &:hover{
      width: 300px;
    }
  }
}

.footer {
  background-color: $primaryColor;
  padding: 100px;
  text-align: left;
  color: $secoundaryColor;

  @media(max-width: 768px) {
    padding: 30px 20px;

    img {
      margin-bottom: 30px;
    }
  }

  h3 {
    font-weight: 700;
  }

  li {
    margin-bottom: 10px;

  }

  a {
    font-size: 24px;
  }
}